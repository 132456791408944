import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { acceptTerms } from '../../services/profileService'; // Adjust the import path
import './Terms.css'; // Reuse the same CSS file

const TermsAndConditions = () => {
    const [isChecked, setIsChecked] = useState(false);
    const [message, setMessage] = useState(null);
    const [messageType, setMessageType] = useState('');
    const navigate = useNavigate();
  
    const handleCheckboxChange = () => {
      setIsChecked(!isChecked);
    };
  
    const handleNextClick = async () => {
        if (isChecked) {
          try {
            const userId = localStorage.getItem('userId');
            const response = await acceptTerms(userId);
      
            if (response.success) {
              setMessage('Terms accepted successfully!');
              setMessageType('success');
      
              const userExists = localStorage.getItem('exists')
      
              // Redirect based on user existence
              setTimeout(() => {
                if (userExists) {
                  navigate('/home'); // Navigate to home page if user exists
                } else {
                  navigate('/onboarding'); // Navigate to onboarding if user does not exist
                }
              }, 1000);
            } else {
              setMessage('Failed to accept terms. Please try again.');
              setMessageType('error');
            }
          } catch (error) {
            console.error('Error accepting terms:', error);
            setMessage('An error occurred. Please try again.');
            setMessageType('error');
          }
        } else {
          setMessage('You must agree to the terms and conditions before proceeding.');
          setMessageType('error');
        }
      };
      
  
    return (
      <div className="terms-page">
        <header className="App-header">
          <div className="navbar">
            <img src={`${process.env.PUBLIC_URL}/entrovLogo.png`} alt="Entrov Logo" className="logo" />
          </div>
        </header>
  
        <h1>Terms & Conditions</h1>
        <p>Please read and accept our Terms and Conditions before proceeding.</p>
  
        {message && (
          <div className={`message ${messageType}`}>
            {message}
          </div>
        )}
  
        <div className="terms-box">
          <iframe
            title="Terms and Conditions"
            src="https://app.termly.io/policy-viewer/policy.html?policyUUID=0837ff33-fe21-452c-89ae-a30f630a4ccc"
            frameBorder="0"
            style={{ width: '100%', height: '100%', border: 'none' }}
            scrolling="yes"
          ></iframe>
        </div>
  
        <div className="checkbox-section">
          <input
            type="checkbox"
            id="terms-checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <label htmlFor="terms-checkbox">
            I agree to the Terms and Conditions
          </label>
        </div>
  
        <button className="next-button" onClick={handleNextClick}>Next</button>
      </div>
    );
  };
  
  export default TermsAndConditions;