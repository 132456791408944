import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createNewGoal } from '../../services/goalService';
import GoalCard from '../../components/GoalCard/GoalCard';
import { handleTitleChange, handleDescriptionChange, handleDateChange, deleteGoal, addGoal } from '../../utils/goalHandlers';
import './Onboarding.css';

const OnboardingPage = () => {
  const [newGoals, setNewGoals] = useState([{ id: 1, title: '', description: '', date: '' }]);
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState('');

  const navigate = useNavigate(); // Hook to navigate programmatically

  const handleNextClick = async () => {
    try {
      const userId = localStorage.getItem('userId');
      const nonDeletedGoals = newGoals.filter(goal => goal.title || goal.description || goal.date);

      const responses = await Promise.all(nonDeletedGoals.map(goal => 
        createNewGoal(userId, goal.title, goal.description, goal.date)
      ));

      const allSuccess = responses.every(response => response.success);

      if (allSuccess) {
        setMessage('All goals created successfully!');
        setMessageType('success');
        setNewGoals([{ id: 1, title: '', description: '', date: '' }]);

        // Redirect to home page after a brief delay to show the message
        setTimeout(() => {
          navigate('/home'); // Replace '/home' with the actual path of your home page
        }, 1000);
      } else {
        setMessage('Some goals failed to create. Please try again.');
        setMessageType('error');
      }
    } catch (error) {
      console.error('Error creating new goals:', error);
      setMessage('An error occurred while creating goals.');
      setMessageType('error');
    }
  };

  return (
    <div className="onboarding-page">
      <header className="App-header">
        <div className="navbar">
          <img src={`${process.env.PUBLIC_URL}/entrovLogo.png`} alt="Entrov Logo" className="logo" />
        </div>
      </header>

      <h1>Let's get you started!</h1>
      <p>What are you working towards getting done?</p>

      {message && (
        <div className={`message ${messageType}`}>
          {message}
        </div>
      )}

      {newGoals.map(goal => (
        <GoalCard 
          key={goal.id} 
          onDelete={() => deleteGoal(goal.id, newGoals, setNewGoals)}
          onTitleChange={(id, value) => handleTitleChange(id, value, newGoals, setNewGoals)}
          onDescriptionChange={(id, value) => handleDescriptionChange(id, value, newGoals, setNewGoals)}
          onDateChange={(id, value) => handleDateChange(id, value, newGoals, setNewGoals)}
        />
      ))}

      <button className="add-goal" onClick={() => addGoal(newGoals, setNewGoals)}>
        Add another goal
      </button>
      <button className="next-button" onClick={handleNextClick}>Next</button>
    </div>
  );
};

export default OnboardingPage;
