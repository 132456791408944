import axios from 'axios';

const API_URL = 'https://api.entrov.com';
const AUTH_TOKEN = process.env.REACT_APP_ENTROV_TOKEN;


export const fetchUserGoals = async (userId, archived = false) => {
  try {
      const response = await fetch(`${API_URL}/goals/fetchAll/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${AUTH_TOKEN}`
          },
          body: JSON.stringify({
              user_id: userId,
              archived: archived,
          }),
      });

      if (!response.ok) {
          throw new Error('Failed to fetch user goals');
      }

      const data = await response.json();
      return data;
  } catch (error) {
      console.error('Error fetching user goals:', error);
      throw error;
  }
};



export const fetchSingleGoal = async (goalId, userId) => {
  try {
      const response = await fetch(`${API_URL}/goals/fetchSingle/`, {
          method: 'POST',  // Ensure POST method is used
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${AUTH_TOKEN}`
          },
          body: JSON.stringify({
              user_id: userId,
              goal_id: goalId,
          }),
      });

      if (!response.ok) {
          throw new Error('Failed to fetch the single goal');
      }

      const data = await response.json();
      return data;
  } catch (error) {
      console.error('Error fetching single goal:', error);
      throw error;
  }
};

// Function to create new user goal
export const createNewGoal = async (userid,title,description = null,due_date = null) => {
  try{
    const response = await axios.post(`${API_URL}/create-goal/` , {
      user_id: userid,
      title: title,
      description: description,
      due_date: due_date
    },
    {
        headers: {
            'Authorization': `${AUTH_TOKEN}`
        }
    }
);
    return response.data;
  } catch (error) {
    console.error('Error creating goal:',error);
    throw error;
  }
  }