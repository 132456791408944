import axios from 'axios';

const API_URL = 'https://api.entrov.com';
const AUTH_TOKEN = process.env.REACT_APP_ENTROV_TOKEN;

// Function to fetch Google user profile
export const fetchGoogleUserProfile = async (accessToken) => {
  try {
    const response = await axios.get('https://www.googleapis.com/oauth2/v1/userinfo?alt=json', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching Google user profile:', error);
    throw error;
  }
};

// Function to log in or create a user
export const loginUser = async (userProfile) => {
  try {
    const response = await axios.post(`${API_URL}/create-user/`, {
      first_name: userProfile.given_name,  // First name from Google response
      last_name: userProfile.family_name,  // Last name from Google response
      email: userProfile.email,            // Email from Google response
      google_id: userProfile.id            // Google ID from Google response
    },
    {
      headers: {
        'Authorization': `${AUTH_TOKEN}`,  // Example of adding an Authorization header
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error logging in:', error);
    throw error;
  }
};
