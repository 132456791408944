// goalHandlers.js

export const handleTitleChange = (id, value, goals, setGoals) => {
    const updatedGoals = goals.map(goal =>
      goal.id === id ? { ...goal, title: value } : goal
    );
    setGoals(updatedGoals);
  };
  
export const handleDescriptionChange = (id, value, goals, setGoals) => {
    const updatedGoals = goals.map(goal =>
      goal.id === id ? { ...goal, description: value } : goal
    );
    setGoals(updatedGoals);
  };
  
export const handleDateChange = (id, value, goals, setGoals) => {
    const updatedGoals = goals.map(goal =>
      goal.id === id ? { ...goal, date: value } : goal
    );
    setGoals(updatedGoals);
  };
  
export const deleteGoal = (id, goals, setGoals) => {
    const updatedGoals = goals.filter(goal => goal.id !== id);
    setGoals(updatedGoals);
  
    // Also remove from local storage if needed
    const storedGoals = JSON.parse(localStorage.getItem('goals')) || [];
    const updatedStoredGoals = storedGoals.filter(goal => goal.id !== id);
    localStorage.setItem('goals', JSON.stringify(updatedStoredGoals));
  };
  
export const addGoal = (goals, setGoals) => {
    setGoals([...goals, { id: goals.length + 1, title: '', description: '', date: '' }]);
  };
  