import React, { useState } from 'react';
import './GoalCard.css';

const GoalCard = ({ initialGoal = { title: '', description: '', date: '' }, onSave, onDelete, onGoalChange }) => {
  const [goal, setGoal] = useState(initialGoal || { title: '', description: '', date: '' });
  const [error, setError] = useState(null);
  const [saving, setSaving] = useState(false);

  const handleTitleChange = (e) => {
    const updatedGoal = { ...goal, title: e.target.value };
    setGoal(updatedGoal);
    if (onGoalChange) {
      onGoalChange(updatedGoal);  // Pass the updated goal back to the parent
    }
  };

  const handleDescriptionChange = (e) => {
    const updatedGoal = { ...goal, description: e.target.value };
    setGoal(updatedGoal);
    if (onGoalChange) {
      onGoalChange(updatedGoal);  // Pass the updated goal back to the parent
    }
  };

  const handleDateChange = (e) => {
    const updatedGoal = { ...goal, date: e.target.value };
    setGoal(updatedGoal);
    if (onGoalChange) {
      onGoalChange(updatedGoal);  // Pass the updated goal back to the parent
    }
  };

  const handleDelete = () => {
    const goals = JSON.parse(localStorage.getItem('goals')) || [];
    const updatedGoals = goals.filter(storedGoal => storedGoal.id !== goal.id);

    localStorage.setItem('goals', JSON.stringify(updatedGoals));

    if (onDelete) {
      onDelete(goal);
    }
  };

  return (
    <div className="goal-card-container">
      <div className="goal-card">
        <button className="delete-button" onClick={handleDelete}>&times;</button>
        <input 
          type="text" 
          className="goal-title" 
          placeholder="Goal title" 
          value={goal.title} 
          onChange={handleTitleChange}
        />
        <textarea 
          className="goal-description" 
          placeholder="Goal description"
          value={goal.description} 
          onChange={handleDescriptionChange}
        />
        <input 
          type="date" 
          className="goal-date" 
          value={goal.date} 
          onChange={handleDateChange}
        />
      </div>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default GoalCard;
