import React from 'react';

const TermsPage = () => {
  return (
    <div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      <iframe
        title="Privacy Policy"
        src="https://app.termly.io/policy-viewer/policy.html?policyUUID=0837ff33-fe21-452c-89ae-a30f630a4ccc"
        frameBorder="0"
        style={{ width: '100%', height: '100vh', border: 'none' }}
        scrolling="yes"
      ></iframe>
    </div>
  );
};

export default TermsPage;
