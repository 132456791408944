import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { fetchGoogleUserProfile, loginUser } from '../../services/authService';
import { useNavigate } from 'react-router-dom';

function LandingPage() {
  const navigate = useNavigate();

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const userProfile = await fetchGoogleUserProfile(tokenResponse.access_token);

        const response = await loginUser(userProfile);

        // Store user data in session
        localStorage.setItem('userId', response.id);
        localStorage.setItem('userName',userProfile.given_name)
        localStorage.setItem('userImage',userProfile.picture)
        localStorage.setItem('userFullName',userProfile.name)
        localStorage.setItem('exists',userProfile.exists)
        
        // Safely set the terms_and_conditions_accepted variable
        let tcAccepted = response.terms_and_conditions_accepted;

        // Check if tcAccepted is 'None' or falsy, and set it to false if so
        if (tcAccepted === 'None' || !tcAccepted) {
          tcAccepted = false;
        }

        localStorage.setItem('tc_accept', tcAccepted);
        
        // Navigate based on user existence and terms acceptance
        if (response.exists) {
          if (tcAccepted) {
            navigate('/home'); // Navigate to the home page if the user exists and has accepted T&C
          } else {
            navigate('/home'); // Navigate to the terms page if the user exists but hasn't accepted T&C
          }
        } else {
          navigate('/onboarding'); // Navigate to the onboarding page if the user is new
        }
      } catch (error) {
        console.error('Error during login process:', error);
      }
    },
    onError: () => console.log('Login Failed'),
  });

  return (
    <div className="App">
      <header className="App-header">
        <div className="navbar">
          <img src={`${process.env.PUBLIC_URL}/entrovLogo.png`} alt="Entrov Logo" className="logo" />
        </div>
        <div className="container">
          <div className="left-content">
            <h1>Get it done, faster.</h1>
            <p>Use AI to track delivery progress, automate administrative updates, and hit your goals faster.</p>
            <button className="btn-primary" onClick={login}>Open App</button>
          </div>
          <div className="right-content">
            <img src={`${process.env.PUBLIC_URL}/landingImage.svg`} alt="Illustration" />
          </div>
        </div>
      </header>
    </div>
  );
}

export default LandingPage;
