// Home.js
import React, { useState, useEffect } from 'react';
import { fetchUserGoals } from '../../services/goalService';
import { submitMessage, finalMessage } from '../../services/updatesService';
import './Home.css';

function Home() {
  const [options, setOptions] = useState([]);
  const [selectedGoalId, setSelectedGoalId] = useState('');
  const [userMessage, setUserMessage] = useState('');
  const [assistantMessage, setAssistantMessage] = useState('');
  const [showAssistantMessage, setShowAssistantMessage] = useState(false);
  const [conversationId, setConversationId] = useState('');
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const userId = localStorage.getItem('userId');
  const userName = localStorage.getItem('userName');

  useEffect(() => {
    const loadGoals = async () => {
      try {
        const goals = await fetchUserGoals(userId, false);
        setOptions(goals);
      } catch (error) {
        console.error('Error loading goals:', error);
      }
    };

    if (userId) {
      loadGoals();
    }
  }, [userId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsNextDisabled(true);

    try {
      const response = await submitMessage(userMessage, userId, selectedGoalId);
      console.log(showAssistantMessage, response.assistantMessage);
      setAssistantMessage(response.assistant_message);
      setConversationId(response.conversation_id);
      setShowAssistantMessage(true);
    } catch (error) {
      console.error('Error submitting message:', error);
    }
  };

  const handleConfirm = async () => {
    try {
      await finalMessage(userId, assistantMessage, conversationId);
      setShowSuccessMessage(true);

      // Clear all input fields
      setSelectedGoalId('');
      setUserMessage('');
      setAssistantMessage('');
      setShowAssistantMessage(false);
      setIsNextDisabled(false);

      // Hide success message after 3 seconds
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);
    } catch (error) {
      console.error('Error sending final message:', error);
    }
  };

  useEffect(() => {
    const textareas = document.querySelectorAll('.form-textarea, .assistant-textarea');
  
    textareas.forEach(textarea => {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
  
      textarea.addEventListener('input', function() {
        this.style.height = 'auto';
        this.style.height = `${this.scrollHeight}px`;
      });
    });
  }, [showAssistantMessage]);

  return (
    <div className="home-page">
    <div className="home-content">
      <div className="greeting-section">
        <h2>
          Hello,<br />
          <span className="user-name">{userName} 👋</span>
        </h2>
      </div>
      <div className="form-container">
        <form className="update-form" onSubmit={handleSubmit}>
          <div className="form-header"><h3>Add an update</h3></div>
          <div className="form-group">
            <select
              className="form-select"
              value={selectedGoalId}
              onChange={(e) => setSelectedGoalId(e.target.value)}
            >
              <option value="">Select a goal</option>
              {options.map((option) => (
                <option key={option.goal_id} value={option.goal_id}>
                  {option.title}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <textarea
              className="form-textarea"
              placeholder="Add your update..."
              value={userMessage}
              onChange={(e) => setUserMessage(e.target.value)}
            ></textarea>
          </div>
          <button type="submit" className="submit-button" disabled={isNextDisabled}>Next</button>
        </form>

        {showAssistantMessage && (
          <div className="assistant-message-box">
            <p>✨ Suggested AI Generated Revision:</p>
            <textarea
              className="form-textarea assistant-textarea"
              value={assistantMessage}
              onChange={(e) => setAssistantMessage(e.target.value)}
            ></textarea>
            <button onClick={handleConfirm} className="submit-button">Confirm</button>
          </div>
        )}

        {showSuccessMessage && (
          <div className="success-message">
            Submission successful!
          </div>
        )}
      </div>
    </div>
    </div>
  );
}

export default Home;
