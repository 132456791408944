import React, { useState, useEffect } from 'react';
import './Sidebar.css';

function Sidebar({ isOpen, toggleSidebar }) {
  const [userImage, setUserImage] = useState('');
  const [isLogoDropdownOpen, setIsLogoDropdownOpen] = useState(false);
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);

  useEffect(() => {
    // Retrieve user image and full name from local storage
    const storedUserImage = localStorage.getItem('userImage');

    if (storedUserImage) {
      setUserImage(storedUserImage);
    }

  }, []); // Empty dependency array to run only once on mount

  const toggleLogoDropdown = () => {
    setIsLogoDropdownOpen(!isLogoDropdownOpen);
  };

  const toggleProfileDropdown = () => {
    setIsProfileDropdownOpen(!isProfileDropdownOpen);
  };

  const handleLogout = () => {
    // Clear local storage and perform logout actions
    localStorage.clear();
    window.location.href = '/'; // Redirect to the landing page or login page
  };

  return (
    <div className="main-container">
      <div className="sidebar-toggle" onClick={toggleSidebar}>
        <img
          src={`${process.env.PUBLIC_URL}/sidebar.svg`}
          alt="Toggle Sidebar"
          className="sidebar-toggle-icon"
        />
      </div>
      <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
        <div className="sidebar-header">
          <img
            src={userImage}
            alt="User Profile"
            className="sidebar-profile-image"
            onClick={toggleProfileDropdown}
          />
          {isProfileDropdownOpen && (
            <div className="sidebar-profile-dropdown">
              <ul>
                <li onClick={handleLogout}>Logout</li>
              </ul>
            </div>
          )}
        </div>
        <ul>
          <li>
            <a href="/home">Home</a>
          </li>
          <li>
            <a href="/goals">Goals</a>
          </li>
        </ul>
        <div className="sidebar-footer">
          <img 
            src={`${process.env.PUBLIC_URL}/entrovLogo.png`} 
            alt="Entrov Logo" 
            className="sidebar-logo" 
            onClick={toggleLogoDropdown}
          />
          {isLogoDropdownOpen && (
            <div className="sidebar-dropdown">
              <ul>
                <li><a href="/privacy">Privacy Policy</a></li>
                <li><a href="/terms-and-conditions">Terms & Conditions</a></li>
                <li><a href="/about-us">About Us</a></li>
                <li><a href="/contact-us">Contact Us</a></li>
              </ul>
            </div>
          )}
        </div>
      </div>

      {/* Main content will be rendered here */}
      <div className="home-content">
        {/* This space will be occupied by the main content component like Home */}
      </div>
    </div>
  );
}

export default Sidebar;
