import React, { useState, useEffect } from 'react';
import { fetchUserGoals, fetchSingleGoal,createNewGoal } from '../../services/goalService';
import Modal from '../../components/Modal/Modal';
import GoalCard from '../../components/GoalCard/GoalCard';
import { submitMessage, finalMessage } from '../../services/updatesService';
// import { handleTitleChange, handleDescriptionChange, handleDateChange, deleteGoal, addGoal } from '../../utils/goalHandlers';
import './Goals.css';

function Goals() {
  const [goals, setGoals] = useState([]);
  const [selectedGoalId, setSelectedGoalId] = useState(null);
  const [conversationId, setConversationId] = useState('');
  const [selectedConversations, setSelectedConversations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noUpdates, setNoUpdates] = useState(false);
  const [expandedConversations, setExpandedConversations] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false); // Modal visibility state
  const [modalContent, setModalContent] = useState('') // Modal content state
  const [isAddingNewGoal, setIsAddingNewGoal] = useState(false); // State to manage new goal addition
  const [userMessage, setUserMessage] = useState('');
  const [assistantMessage, setAssistantMessage] = useState('');
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [showAssistantMessage, setShowAssistantMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [newGoal, setNewGoal] = useState({ id:'1',title: '', description: '', date: '' });

  const userId = localStorage.getItem('userId');
  // const userName = localStorage.getItem('userName');

  useEffect(() => {
    loadGoals();
  }, []);

  const loadGoals = async () => {
    try {
      const userId = localStorage.getItem('userId');
      const fetchedGoals = await fetchUserGoals(userId, false);
      setGoals(fetchedGoals);
    } catch (error) {
      console.error('Error loading goals:', error);
    }
  };
  
  const handleGoalChange = (e) => {
    const { name, value } = e.target;
    setNewGoal((prevGoal) => ({
      ...prevGoal,
      [name]: value,
    }));
  };

  const handleGoalClick = async (goalId) => {
    if (goalId === selectedGoalId) {
      setSelectedGoalId(null);
      setSelectedConversations([]);
      setNoUpdates(false);
      return;
    }

    setSelectedGoalId(goalId);
    setLoading(true);
    setNoUpdates(false);
    setExpandedConversations({});

    try {
      const userId = localStorage.getItem('userId');
      const response = await fetchSingleGoal(goalId, userId);
      if (response.conversations.length > 0) {
        setSelectedConversations(response.conversations);
      } else {
        setNoUpdates(true);
      }
    } catch (error) {
      console.error('Error fetching single goal:', error);
      setNoUpdates(true);
    } finally {
      setLoading(false);
    }
  };

  const handleToggleExpand = (event, index) => {
    event.stopPropagation();
    setExpandedConversations(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  const handleAddNewUpdate = async () => {
    // Set modal content to 'newUpdate' and show the modal when the button is clicked
    setModalContent('newUpdate');
    setIsModalVisible(true);
  };
  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form behavior

    // Disable the "Next" button
    setIsNextDisabled(true);

    try {
      const response = await submitMessage(userMessage, userId, selectedGoalId);
      console.log(showAssistantMessage, response.assistantMessage);
      setAssistantMessage(response.assistant_message);
      setConversationId(response.conversation_id);
      setShowAssistantMessage(true);
    } catch (error) {
      console.error('Error submitting message:', error);
    }
  };

  // Function to confirm the final message
  const handleConfirm = async () => {
    try {
      // Simulate API call to submit the final message with the conversation ID
      const response = await finalMessage(userId, assistantMessage, conversationId);
      setShowSuccessMessage(true);
      // Clear all input fields
      setSelectedGoalId(''); // Reset the goal selection
      setConversationId(null); // Clear conversation ID
      setUserMessage(''); // Reset the user message input
      setAssistantMessage(''); // Reset the assistant message input
      setShowAssistantMessage(false); // reset the form to remove assistant message.
      setIsNextDisabled(false);
      setIsModalVisible(false); // Close the modal after the confirmation
      // Reset all form inputs after 3 seconds
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);
    } catch (error) {
      console.error('Error confirming message:', error);
    }
  };

  const handleAddNewClick = () => {
    setIsAddingNewGoal(true); // Show the new goal card at the top of the list
  };

  const handleSaveNewGoal = async () => {
    try {
      const userId = localStorage.getItem('userId');
      console.log({'userId':userId,'title':newGoal.title,'desc':newGoal.description,'date':newGoal.date})
      const response = await createNewGoal(userId, newGoal.title, newGoal.description, newGoal.date);
      if (response) {
        setIsAddingNewGoal(false);
        await loadGoals(); // Reload goals to match the newly saved goal
      }
    } catch (error) {
      console.error('Error saving new goal:', error);
    }
  };

  const handleCloseModal = () => {
    loadGoals();
    setIsModalVisible(false); // Hide the modal
  };

  const renderConversationText = (text, index) => {
    if (text.length <= 220) {
      return text;
    }

    if (expandedConversations[index]) {
      return (
        <>
          {text} <button onClick={(e) => handleToggleExpand(e, index)}>See Less</button>
        </>
      );
    } else {
      return (
        <>
          {text.substring(0, 220)}... <button onClick={(e) => handleToggleExpand(e, index)}>See More</button>
        </>
      );
    }
  };

  return (
    <div className="goals-page">
      <div className="content-wrapper">
        <div className="header">
          <h2>Your Goals</h2>
          <div className="actions">
            <button className="add-new-button" onClick={handleAddNewClick}>Add New</button>
          </div>
        </div>
        <div className="goal-list">
        {isAddingNewGoal && (
          <div className="new-goal-container">
            <GoalCard
              onSave={handleSaveNewGoal} // Pass this function to save the goal
              onDelete={() => setIsAddingNewGoal(false)}
              onGoalChange={handleGoalChange} // Handle changes from GoalCard
            />
            <div className="goal-save-bar">
              <button className="save-goal-button" onClick={handleSaveNewGoal}>Save Goal</button>
            </div>
          </div>
        )}
          {goals.map((goal) => (
            <div
              className="goal-container"
              key={goal.goal_id}
              onClick={() => handleGoalClick(goal.goal_id)}
            >
              <div className="goal-item">
                <div className="goal-header">
                  <div className="goal-title">{goal.title}</div>
                  <div className="goal-due-date">
                    {goal.due_date ? new Date(goal.due_date).toLocaleDateString() : 'No due date'}
                  </div>
                </div>
                <div className="goal-description">
                  {goal.description}
                </div>
              </div>

              {selectedGoalId === goal.goal_id && (
                <div className="conversations-list">
                  {loading ? (
                    <div className="loading-bar"></div>
                  ) : noUpdates ? (
                    <>
                      <div className="no-updates-message">No updates provided</div>
                      <button className="add-new-update-button" onClick={handleAddNewUpdate}>Add New Update</button>
                    </>
                  ) : (
                    <>
                      <table>
                        <tbody>
                          {selectedConversations.map((conversation, index) => (
                            <tr key={index}>
                              <td>{conversation.timestamp ? new Date(conversation.timestamp).toLocaleString() : ''}</td>
                              <td>{renderConversationText(conversation.message, index)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <button className="add-new-update-button" onClick={handleAddNewUpdate}>Add New Update</button>
                    </>
                  )}
                </div>
              )}
            </div>
          ))}
          {showSuccessMessage && (
          <div className="success-dialog">
            <p>Submission successful!</p>
          </div>
        )}
        </div>
      </div>

    <Modal isVisible={isModalVisible} onClose={handleCloseModal}>
      <div className="form-container">
        <form className="update-form" onSubmit={handleSubmit}>
          <div className="form-header"><h3>Add an update</h3></div>
          <div className="form-group">
            <select
              className="form-select"
              value={selectedGoalId}
              onChange={(e) => setSelectedGoalId(e.target.value)}
            >
              <option value="">Select a goal</option>
              {goals.map((goal) => (
                <option key={goal.goal_id} value={goal.goal_id}>
                  {goal.title}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <textarea
              className="form-textarea"
              placeholder="Add your update..."
              value={userMessage}
              onChange={(e) => setUserMessage(e.target.value)}
            ></textarea>
          </div>
          <button type="submit" className="submit-button" disabled={isNextDisabled}>Next</button>
        </form>

        {showAssistantMessage && (
          <div className="assistant-message-box">
            <p>✨ Suggested AI Generated Revision:</p>
            <textarea
              className="form-textarea assistant-textarea"
              value={assistantMessage}
              onChange={(e) => setAssistantMessage(e.target.value)}
            ></textarea>
            <button onClick={handleConfirm} className="submit-button">Confirm</button>
          </div>
        )}
      </div>
    </Modal>
    </div>
  );
};

export default Goals;
