import axios from 'axios';

const API_URL = 'https://api.entrov.com';
const AUTH_TOKEN = process.env.REACT_APP_ENTROV_TOKEN;


export const submitMessage = async (message, userId, goalId) => {
  try {
    const response = await axios.post(`${API_URL}/submitMessage/`, {
      message,
      user_id: userId,
      goal_id: goalId,
    },
    {
      headers: {
          'Authorization': `${AUTH_TOKEN}`
      }
  });
    return response.data;
  } catch (error) {
    console.error('Error submitting message:', error);
    throw error; // Re-throw the error to handle it in the calling function
  }
};

export const finalMessage = async (userId, message, conversationId) => {
    try {
      const response = await axios.post(`${API_URL}/finalMessage/`, {
        user_id: userId,
        message,
        conversation_id: conversationId,
      },
      {
        headers: {
            'Authorization': `${AUTH_TOKEN}`
        }
    });
      return response.data;
    } catch (error) {
      console.error('Error sending final message:', error);
      throw error;
    }
  };